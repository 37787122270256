<template>
  <div class="" id="resources">
    <div class="container-fluid">
      <div class="row pt-3 pb-2">
        <div class="col-12">
          <h3 class="bg-danger text-white rounded p-3">Coming soon. Stay connected</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Resources"
  }
</script>

<style scoped>

</style>